import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    user: any;

    constructor(
        private _auth: AuthenticationService,
        private _router: Router
    ) { }

    canActivate(): true | UrlTree {
        return this.checkRole();
    }

    private checkRole(): true | UrlTree {
        
        this._auth.getUser().subscribe(user => {
            if (user) {
                this.user = user;
            }
        });

        if (this.user.idTokenClaims.roles.includes('bpmreportingproductteam')) {
            return true;
        } else {
            return this._router.parseUrl('error');
        }
    }
}