import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FileService } from '../../services/api/file.service';
import { IReportFileFolder, IReportFilesData, IFileUrl } from '../../models/report-file.interface';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { BasicsService } from '../../services/shared/basics.service';
import { UsersService } from '../../services/api/users.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'bpm-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.scss'],
})
export class ViewFileComponent implements OnInit, OnDestroy {
  private _loggedUser: any;
  public dataLoaded: boolean = false;
  public isDraggable: boolean = false;
  public userRole = 'unauthorized';
  private _uploadFileSubscription$: Subscription;
  private _reportSnapshot$: Subscription;
  public fileList: IReportFileFolder;
  public fileToUpload: FileList;
  public fileName = '';
  public error: string;
  public fileSize: string;
  public maxFileSize: number = 10000000;
  public selectedFile: IReportFilesData;
  public signedUrl = '';
  public searchText = '';

  public close: string = 'close';
  public browseTxt: string = 'Browse';
  public cancelTxt: string = 'Cancel';

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    if (this.isDraggable) {
      event.preventDefault();
    }
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    if (this.isDraggable) {
      event.preventDefault();
      event.stopPropagation();
      if (event.dataTransfer.files) {
        let files: FileList = event.dataTransfer.files;
        this.saveFiles(files);
      }
    }
  }

  constructor(
    private _fs: FileService,
    private _router: Router,
    private _bs: BasicsService,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    private _userService: UsersService
  ) {}

  ngOnInit() {
    this.getAuthUser();
    this.getReportSnapshot();
    this.userRole = this._userService.userRole.getValue();
    if (this.userRole === 'unauthorized') {
      this._router.navigate(['/unauthorized']);
    } else if (this.userRole !== 'super') {
      this._router.navigate(['/home']);
    }
  }

  private getAuthUser(): void {
    this._authService.getUser().subscribe(user => {
      this._loggedUser = user;
    });
  }

  getReportSnapshot(): void {
    this._reportSnapshot$ = this._route.paramMap.subscribe(params => {
      const param = params.get('reportFile');
      switch (param) {
        case 'avgPriceReport':
          this.getReportFiles('avgPrice');
          this.isDraggable = false;
        break;
        case 'lmpMccReport':
          this.getReportFiles('lmpMcc');
          this.isDraggable = false;
        break;
        case 'fuelPricing':
          this.getReportFiles('fuelPricing');
          this.isDraggable = true;
        break;
        default:
          this.isDraggable = false;
        break;
      }
    });
  }

  getReportFiles(report: string): void {
    this.dataLoaded = false;
    this._uploadFileSubscription$ = this._fs.getReportFiles(report).subscribe(fData => {
      if (fData) {
        this.dataLoaded = true;
        this.fileList = fData;
      }
    },
    (e) => {
      console.error('Error: ', e);
      this._bs.openSnackBar('An error occured while retriving report files list data', false);
    });
  }

  onFileClick(file: IReportFilesData) {
    this.selectedFile = file;
    this._fs.getPreSignedUrl(file.key, this.fileList.report).subscribe((x: IFileUrl) => {
      window.open(x.url, '_blank');
    },
    (e) => {
      console.error('Error: ', e);
      this._bs.openSnackBar('An error occured while downloading a file', false);
    });
  }

  onFileChange(event: any) {
    let files: FileList = event.target.files;
    this.saveFiles(files);
  }

  saveFiles(files: FileList): void {
    this.error = null;
    this.fileName = null;
    this.fileSize = null;

    if (files.length > 1) {
      this.error = 'Only one file can be uploaded at a time.';
    } else {
      const fileTypeExtension = files[0].name.substring(
        files[0].name.lastIndexOf('.') + 1
      );
      if (
        fileTypeExtension.toLowerCase() == 'xls' ||
        fileTypeExtension.toLowerCase() == 'xlsm' ||
        fileTypeExtension.toLowerCase() == 'xlsx' ||
        fileTypeExtension.toLowerCase() == 'csv'
      ) {
        if (files[0].size > this.maxFileSize) {
          console.info(
            files[0].size,
            this.maxFileSize,
            files[0].size > this.maxFileSize
          );
          this.error = 'Maximum file size exceeded.';
        } else {
          this.fileName = files[0].name;
          this.fileSize = files[0].size / 1000 + 'kb';
          this.fileToUpload = files;
        }
      } else {
        this.error = 'Incorrect file type. Excel document required.';
      }
    }
  }

  removeFile() {
    this.fileName = null;
    this.fileSize = null;
  }

  uploadFile() {
    this._uploadFileSubscription$ = this._fs
      .uploadFile(this.fileToUpload[0], this._loggedUser.username)
      .subscribe({
        next: (res) => {
          this._bs.openSnackBar('File uploaded successfully');
          this.removeFile();
          this.getReportFiles('fuelPricing');
        },
        error: (err) => {
          console.error('Error while uploading file: ', err);
          this._bs.openSnackBar('An error occured while uploading a file', false);
        },
      });
  }

  ngOnDestroy(): void {
    this._uploadFileSubscription$.unsubscribe();
    this._reportSnapshot$.unsubscribe();
  }

}
