import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UsersService } from '../../../services/api/users.service';
import { InstructionsComponent } from './instructions/instructions.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  public enableNav = false;
  public isProductTeam = false;

  private _userSubscription$: Subscription;

  constructor(
    private _us: UsersService,
    private _router: Router,
    private _route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isProductTeam = this._us.isProductTeam.getValue();
    this._userSubscription$ = this._us.userRole.subscribe(role => {
      if (role !== 'unauthorized') {
        this.enableNav = true;
      } else {
        this._router.navigate(['/unauthorized']);
      }
    });
  }

  navigateToPlant() {
    this.enableNav ? this._router.navigate([`/plant-notifications/subscriptions`], { relativeTo: this._route }) : this._router.navigate(['/error']);
  }

  navigateToReport() {
    this.enableNav ? this._router.navigate(['/reports/subscriptions'], { relativeTo: this._route }) : this._router.navigate(['/error']);
  }

  openInstructions() {
    this.dialog.open(InstructionsComponent);
  }

  ngOnDestroy(): void {
    this._userSubscription$.unsubscribe();
  }

}
