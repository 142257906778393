import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';

import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './config/msal.config';

import { AppConfig } from './config/app.config';
import { AppComponent } from './app.component';
import { RoleGuard } from './services/guards/role.guard';
import { BpmHeaderComponent } from './components/shared/bpm-header/bpm-header.component';
import { AppRoutingModule } from './app.routing.module';
import { HomeComponent } from './components/shared/home/home.component';
import { ErrorComponent } from './components/shared/error/error.component';
import { SharedModule } from './modules/shared.module';
import { EditUserComponent } from './components/shared/users-list/edit-user/edit-user.component';
import { DeleteUserComponent } from './components/shared/users-list/delete-user/delete-user.component';
import { UnauthorizedComponent } from './components/shared/unauthorized/unauthorized.component';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';
import { NetworkInterceptorService } from './services/shared/network-interceptor.service';
import { ViewFileComponent } from './components/view-file/view-file.component';
import { SortPipe } from './pipies/sort.pipe';

@NgModule({
  declarations: [
    AppComponent,
    BpmHeaderComponent,
    HomeComponent,
    ErrorComponent,
    EditUserComponent,
    DeleteUserComponent,
    UnauthorizedComponent,
    ImageLoaderComponent,
    ViewFileComponent,
    SortPipe
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    MsalModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    SortPipe,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: getUseFactory,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,      
      useClass: NetworkInterceptorService,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    RoleGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

export function getUseFactory(config: AppConfig) {
  return () => config.load();
}
