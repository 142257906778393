import { NgModule } from '@angular/core';
import {
    DeFooterModule,
    DeHeaderModule  
} from '@de-electron/electron-angular-components';

const modules = [
    DeFooterModule,
    DeHeaderModule
];

@NgModule({
    imports: [...modules],
    exports: [...modules]
})
export class DeElectronModule {}