import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IUserListItem } from './models/user.interface';
import { UsersService } from './services/api/users.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { BasicsService } from './services/shared/basics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public loginDisplay = false;
  public header = true;
  public loading$ = this._bs.loading$;
  public isUserDataLoaded = false;

  private _loggedUserSubscription$: Subscription;

  constructor(
    private _authService: AuthenticationService,
    private _us: UsersService,
    private _router: Router,
    private _bs: BasicsService
  ) {}

  ngOnInit(): void {
    this._bs.showHeader.next(true);
    this.hideHeader();
    this._authService.init();
    this.getUser();
    this.loadLinkTag('https://fonts.gstatic.com', 'preconnect');
    this.loadLinkTag('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap', 'stylesheet');
    this.loadLinkTag('https://fonts.googleapis.com/icon?family=Material+Icons', 'stylesheet');
  }

  private loadLinkTag(url: string, rel: string) {
    let node = document.createElement('link');
    node.href = url;
    node.rel = rel;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  getUser() {
    this.isUserDataLoaded = false;
    this._loggedUserSubscription$ = this._authService.getUser().subscribe(
      user => {
        if (user) {
          this.isUserDataLoaded = true;
          if (user?.idTokenClaims?.roles) {
            this.handleAuthUser(user);
          } else if (!user?.idTokenClaims?.roles) {
            this.handleUnauthUser();
          }
        }
      },
      error => {
        this.isUserDataLoaded = false;
      },
      () => {
        this.isUserDataLoaded = false;
      }
    );
  }

  private hasRole(roles: string[], targetRoles: string[]): boolean {
    return roles.some((role) => targetRoles.includes(role));
  }

  private handleAuthUser(user: any) {
    console.info('user logged in ', true);
    const selectedUser: IUserListItem = {
      id: user.localAccountId,
      mail: user.username,
      is_internal: true,
    };
    this._us.selectedUser$.next(selectedUser);
    if (this.hasRole(user?.idTokenClaims?.roles, ['bpmreportingproductteam', 'BPM_APP_USER_APPROVERS',])) {
      this._us.userRole.next('super');
      this.getAzureUser();
      this.getExUser();
    } else if (user?.idTokenClaims?.roles.includes('NAM_FSO_APP_USERS')) {
      this._us.userRole.next('basic');
    }

    if (user?.idTokenClaims?.roles.includes('bpmreportingproductteam')) {
      this._us.isProductTeam.next(true);
    }
  }

  private handleUnauthUser() {
    this.loginDisplay = true;
    this._router.navigate(['/unauthorized']);
  }

  private hideHeader(): void {
    this._bs.showHeader.subscribe((x) => {
      this.header = x;
    });
  }

  private getAzureUser(): void {
    this._us.onInit();
  }

  private getExUser(): void {
    this._us.getExternalUsers();
  }

  ngOnDestroy(): void {
    this._authService.destroySubscriptions();
    this._us.destroyAzure.next(true);
    this._us.destroyExUser.next(true);
    this._loggedUserSubscription$.unsubscribe();
  }
}
