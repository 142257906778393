import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry, timeout } from 'rxjs/operators';
import { environments } from '../../../config/env.config';
import { env } from '../../../config/env';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private _http: HttpClient) {}

  uploadFile(file: File, user_id: string): Observable<any> {
    const formData = new FormData();
    formData.append(file.name, file);
    formData.append('updated_by', user_id);
    return this._http.post(`${environments[env.env].lambdaBaseUrl}/report-files`, formData);
  }

  getReportFiles(report: string): Observable<any> {
    return this._http.get(environments[env.env].lambdaBaseUrl + `/report-files?report=${report}`)
      .pipe(
        timeout(25000),
        retry(2),
        map(res => {
          return res;
        })
      );
  }

  getPreSignedUrl(key: string, report: string): Observable<any> {
    return this._http.get(environments[env.env].lambdaBaseUrl + `/report-signed-url?key=${key}&report=${report}`)
    .pipe(
      timeout(25000),
      retry(2),
      map(res => {
        return res;
      })
    );
  }
}
