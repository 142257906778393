<h1 mat-dialog-title>Edit External User Email.</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill" class="email-form-field">
    <mat-label for="edit_email_input">Edit email</mat-label>
    <input type="email" id="edit_email_input" name="user_email" aria-label="user_email" required matInput
      [formControl]="emailFormControl" placeholder="Ex. pat@example.com" [(ngModel)]="email">
    <mat-error
      *ngIf="emailFormControl.hasError('email') &&
      !emailFormControl.hasError('required') &&
      !emailFormControl.hasError('maxlength')">
      Please enter a valid email address
    </mat-error>
    <mat-error
      *ngIf="emailFormControl.hasError('maxlength') &&
      !emailFormControl.hasError('required')">
      Maximum length of email is 50 characters only
    </mat-error>
    <mat-error *ngIf="emailFormControl.hasError('required')">
      Email is <strong>{{requiredTxt}}</strong>
    </mat-error>
    <mat-error *ngIf="emailFormControl.hasError('forbiddenEmail')">
      <strong>@duke-energy.com</strong> domain is not allowed
    </mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{cancelTxt}}</button>
  <button
    mat-button
    (click)="onEditClick(email)"
    [mat-dialog-close]="email"
    cdkFocusInitial
    [disabled]="!email ||
    emailFormControl.hasError('email') ||
    emailFormControl.hasError('maxlength') ||
    !(emailFormControl.dirty) ||
    emailFormControl.invalid">{{saveTxt}}</button>
</div>