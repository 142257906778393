<h1 mat-dialog-title>Are you sure you want to Delete a User?</h1>
<div mat-dialog-actions>
  <p>Delete user will also delete any subscriptions the user has!</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{cancelTxt}}</button>
  <button
    mat-raised-button
    color="warn"
    (click)="onDeleteClick()"
    [mat-dialog-close]="data"
    cdkFocusInitial>{{deleteTxt}}</button>
</div>