import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BasicsService {

  public showHeader = new BehaviorSubject<boolean>(true);

  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();

  constructor(private _snackBar: MatSnackBar) {}

  show() {
    this._loading.next(true);
  }

  hide() {
    this._loading.next(false);
  }

  openSnackBar(message: string, status = true) {
    this._snackBar.open(message, undefined, {
      duration: 3000,
      panelClass: ['mat-toolbar', status ? 'mat-primary' : 'mat-warn']
    });
  }
}
