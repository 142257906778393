import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { UsersService } from '../../../../services/api/users.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bpm-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit, OnDestroy {

  emailFormControl = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50), this.emailValidator ]);
  loggedUser: any;

  public requiredTxt: string = 'required';
  public addTxt: string = 'Add';
  public cancelTxt: string = 'Cancel';

  private _userSubscription$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public email: string,
    private _us: UsersService,
    private _auth: AuthenticationService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onAddClick(email: string): void {
    const reqObj = { email_address: email, updated_by: this.loggedUser };
    this._us.addUser(reqObj);
  }

  ngOnInit(): void {
    this._userSubscription$ = this._auth.getUser().subscribe(u => {
      this.loggedUser = u.username;
    });
  }

  emailValidator(control: AbstractControl):  ValidationErrors | null {
      const forbidden = control?.value?.toLowerCase().includes('@duke-energy.com');
      return forbidden ? {forbiddenEmail: {value: control.value}} : null;
  }

  ngOnDestroy(): void {
    this._userSubscription$.unsubscribe();
  }
}
