<div class="users-container">
    <mat-card class="bpm-border-radius">
        <mat-card-title>{{usersTxt}}</mat-card-title>
        <mat-form-field class="search-form-field" appearance="fill">
            <mat-label for="filter_input">search users</mat-label>
            <input id="filter_input" name="filter_email" aria-label="filter_email" matInput type="text"
                [(ngModel)]="searchText">
            <button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="searchText=''">
                <mat-icon fontIcon="home">{{close}}</mat-icon>
            </button>
        </mat-form-field>
        <mat-card-content class="users-body">
            <mat-selection-list #selectedUserRef
                (selectionChange)="onUserSelect(selectedUserRef?.selectedOptions.selected[0]?.value)"
                [multiple]="false">
                <mat-accordion [multi]="true">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title><span class="bpm-text-center">Duke Internal Users</span></mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-list-option
                            *ngFor="let aUser of filteredAzureUsersList; let i = index;"
                            [selected]="aUser.id === selectedListOption"
                            class="azure-user"
                            [value]="aUser">
                            <span [id]="aUser?.id" *ngIf="aUser?.mail !== null">
                                {{aUser?.mail}}
                            </span>
                        </mat-list-option>
                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title><span class="bpm-text-center">External Users</span></mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-list-option *ngFor="let eUser of filteredExternalUsersList; let i = index;"
                            [selected]="eUser.id === selectedListOption" class="ex-user" [value]="eUser">
                            <div class="option">
                                <span [id]="eUser?.id" *ngIf="eUser?.mail !== null">
                                    {{eUser?.mail}}
                                </span>
                                <div *ngIf="selectedUserRef?.selectedOptions.selected[0]?.value.mail === eUser.mail"
                                    class="buttons">
                                    <button (click)="onEditBegin($event, eUser)" mat-icon-button color="primary"
                                        aria-label="Example icon button with a home icon">
                                        <mat-icon>{{edit}}</mat-icon>
                                    </button>
                                    <button (click)="onDeleteBegin($event, eUser)" mat-icon-button color="warn"
                                        aria-label="Example icon button with a menu icon">
                                        <mat-icon>{{delete}}</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-list-option>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-selection-list>
        </mat-card-content>
        <mat-card-footer class="bpm-card-footer">
            <button class="bpm-border-radius column-button" mat-raised-button color="primary" (click)="onAddBegin()">Add
                External
                User</button>
        </mat-card-footer>
    </mat-card>
</div>