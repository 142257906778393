<h1 mat-dialog-title>{{pageTitleTxt}}</h1>
<mat-dialog-content class="mat-typography">
    <mat-accordion [multi]="true">

        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2>{{page1HeaderTxt}}</h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>To get to Plant Notification Subscription page, user has 2 options:</p>
            <ol>
                <li>Click “Plant-Notification” button directly from homepage.</li>
                <li>Click “Subscription” and then “Plant-Notification-Subscription” from top right navigation dropdown.
                </li>
            </ol>
            <h3>Add subscriptions</h3>
            <p>Select the plant unit name in the “Notification” section to subscribe, and then click "Add" button.</p>
            <h3>Remove subscriptions</h3>
            <p>Select the plant unit name in the “Subscriptions” to unsubscribe and click "Remove" button.</p>
            <h3>Save/Cancel changes</h3>
            <p>Click Save/Cancel button at the bottom right to save/cancel current changes.</p>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2>{{page2HeaderTxt}}</h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>To get to Report Subscription page, user has 2 options:</p>
            <ol>
                <li>Click on “Reporting-Subscription” button directly from homepage.</li>
                <li>Click “Subscription” and then “Report-Subscription” from top right navigation dropdown.</li>
            </ol>
            <h3>Add subscriptions</h3>
            <p>Select the report name in the “Reports” section to subscribe, and then click "Add" button.</p>
            <h3>Remove subscriptions</h3>
            <p>Select the report name in the “Reports” to unsubscribe and then click "Remove" button.</p>
            <h3>Save/Cancel changes</h3>
            <p>Click Save/Cancel button at the bottom right to save/cancel current changes.</p>
            <h3>Review sample report</h3>
            <p>Click on small report icon button next to the report name in the “Reports” section.</p>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2>{{usersTxt}}</h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>There are some functions are only available to
                “Super User” role that are FSO managers and/or their
                delegates who can manage subscriptions for someone else.</p>
            <h3>External Users</h3>
            <p>External Uses management function is only available to “Super User” role. External User is intended for
                the non-Duke users who need to receive some reports/notifications with business justification.</p>
            <h3>How to add external user?</h3>
            <p>To add an external user, click on “Add External User” button and you will be promoted to enter a validate
                non-duke email address. Enter valid email id then click on Add button.</p>
            <h3>How to edit external user?</h3>
            <p>Select the external user you want to edit, click on “Edit” icon next to the external user email address.
                There will be “Edit External User Email” pop-up window to edit. Then click “Save” button to save the
                change, or “Cancel” button to cancel the change.</p>
            <h3>How to delete external user?</h3>
            <p>Select the external user you want to delete, click on “Delete” icon next to the external user email
                address. There will be pop-up window to ask you if you are sure to delete the user as well as the
                subscriptions for that user. Click “Delete” button to confirm the deletion, or “Cancel” button to
                cancel.</p>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2>{{fileHeaderTxt}}</h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>To get to File Manager page,
                click File Manager from top right navigation then select report</p>
            <h3>Download file</h3>
            <p>To download a file click on file name
                from the list of files on left side of card.</p>
            <h3>Upload file</h3>
            <p>To upload a file drag and drop any excel file or click on
                browse button then click on save button at the bottom right.</p>
            <h3>Cancel changes</h3>
            <p>To cancel changes click on close icon next to
                added/drpped file name or click on cancel button to undo changes</p>
        </mat-expansion-panel>

    </mat-accordion>

</mat-dialog-content>