import { Component } from '@angular/core';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent {
  public pageTitleTxt: string = 'Instructions';
  public page1HeaderTxt: string = 'Plant Notification Subscription';
  public page2HeaderTxt: string = 'Reports Subscription';
  public usersTxt: string = 'Users';
  public fileHeaderTxt: string = 'File Manager';
}
