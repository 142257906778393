import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from '../../../services/api/users.service';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { DeleteUserComponent } from './delete-user/delete-user.component';
import { IUserListItem } from '../../../models/user.interface';
import { Subscription } from 'rxjs';
import { BasicsService } from '../../../services/shared/basics.service';

@Component({
  selector: 'bpm-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, AfterViewInit, OnDestroy {

  public email: string;
  public searchText = '';
  private _azureUsersList?: IUserListItem[] = [];
  private _externalUsersList?: IUserListItem[];
  private _azureUserSubscription$: Subscription;
  private _extUserSubscription$: Subscription;
  public panelOpenState = true;
  public selectedListOption = '';

  public usersTxt: string = 'Users'; 
  public close: string = 'close';
  public edit: string = 'edit';
  public delete: string = 'delete';

  constructor(
    private _bs: BasicsService,
    private _us: UsersService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) document: Document
  ) { }

  ngOnInit(): void {
    this.checkSelectedUser();
    this.getAzureUsers();
    this.getExternalUsers();
  }

  ngAfterViewInit(): void {
    this.scrolltoSelectedUser();
  }

  onUserSelect(user): void {
    this._us.selectedUser$.next(user);
    this.selectedListOption = user.id;
  }

  checkSelectedUser(): void {
    this._us.selectedUser$.subscribe(user => {
      if (user) {
        setTimeout(() => {
          this.selectedListOption = user.id;
          const elId = document.getElementById(this.selectedListOption);
          elId ? elId.scrollIntoView({ block: "center" }) : console.info('bpmreportingproductteam 2');
        }, 2000);
        
      } else {
        this._bs.openSnackBar('Loading');
      }
    });
  }

  scrolltoSelectedUser(): void {
    setTimeout(() => {
      const elId = document.getElementById(this.selectedListOption);
      elId ? elId.scrollIntoView({ block: "center" }) : console.info('bpmreportingproductteam 1');
    }, 2500);
  }

  get filteredAzureUsersList(): IUserListItem[] {
    return this._azureUsersList?.filter(item => item.mail.toLowerCase().includes(this.searchText.toLowerCase())).sort((a, b) => a.mail.toLowerCase().localeCompare(b.mail.toLowerCase()));
  }

  get filteredExternalUsersList(): IUserListItem[] {
    return this._externalUsersList?.filter(item => item.mail.toLowerCase().includes(this.searchText.toLowerCase())).sort((a, b) => a.mail.toLowerCase().localeCompare(b.mail.toLowerCase()));
  }

  private getAzureUsers() {
    this._azureUserSubscription$ = this._us.azureUsers.subscribe(data => {
      this._azureUsersList = data?.filter((user, ind) => data.findIndex(item => item.id === user.id) === ind);
    });
  }

  private getExternalUsers() {
    this._extUserSubscription$ = this._us.externalUser.subscribe(data => {
      this._externalUsersList = data;
    });
  }

  onAddBegin(): void {
    const dialogRef = this.dialog.open(AddUserComponent, {
      data: this.email,
      width: '400px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.email = '';
    });
  }

  onEditBegin(event, data) {
    event.stopPropagation();
    this.dialog.open(EditUserComponent, {
      data: data,
      width: '400px'
    });
  }

  onDeleteBegin(event, data) {
    event.stopPropagation();
    this.dialog.open(DeleteUserComponent, {
      data: data
    });
  }

  ngOnDestroy(): void {
    this._azureUserSubscription$.add(this._extUserSubscription$).unsubscribe();
  }
}
