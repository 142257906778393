import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
  selector: 'bpm-header',
  templateUrl: './bpm-header.component.html'
})
export class BpmHeaderComponent implements OnInit, OnDestroy {

  public headerLinks: any[] = [{ viewValue: 'Home', value: 'home', showSubLinks: false}];
  public title = 'BPM Reporting Subscription Manager';
  public user: any;
  private userSubscription$: Subscription;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getAuthUser();
  }

  private getAuthUser() {
    this.userSubscription$ = this._authService.getUser().subscribe(user => {
      if (user?.idTokenClaims?.roles) {
        this.user = user;
        const isProductTeam = user.idTokenClaims.roles.includes('bpmreportingproductteam');
        if (isProductTeam) {
          this.headerLinks.push(
            {
              viewValue: 'Subscription', value: 'subscription', showSubLinks: true, subLinks: [
                {
                  viewValue: 'Plant-Notification-Subscription', value: 'notifications'
                },
                {
                  viewValue: 'Reporting-Subscription', value: 'report'
                }
              ]
            },
            {
              viewValue: 'Configuration', value: 'configuration', showSubLinks: true, subLinks: [
                {
                  viewValue: 'Reports Configuration', value: 'report-config'
                }
              ]
            },
            {
              viewValue: 'File Manager', value: 'viewFile', showSubLinks: true, subLinks: [
                {
                  viewValue: 'Average Price Export Report', value: 'avgPriceReport'
                },
                {
                  viewValue: 'LMP & MCC Basis Report', value: 'lmpMccReport'
                }
                ,
                {
                  viewValue: 'MIRA Offer Check Update', value: 'fuelPricing'
                }
              ]
            },
            {
              viewValue: `${this.user.name}`, value: 'user', showUserIcon: true, subLinks: [
                { viewValue: 'Sign Out', value: 'signOut' }
              ]
            }
          );
        } else {
          this.headerLinks.push(
            {
              viewValue: 'Subscription', value: 'subscription', showSubLinks: true, subLinks: [
                {
                  viewValue: 'Plant-Notification-Subscription', value: 'notifications'
                },
                {
                  viewValue: 'Reporting-Subscription', value: 'report'
                }
              ]
            },
            {
              viewValue: `${this.user.name}`, value: 'user', showUserIcon: true, subLinks: [
                { viewValue: 'Sign Out', value: 'signOut' },
              ]
            }
          );
        }
      }
    });
  }

  onNavClick(e: any) {
    switch (e) {
      case 'notifications':
        this._router.navigate([`/plant-notifications/subscriptions`], { relativeTo: this._route });
        break;
      case 'report':
        this._router.navigate(['/reports/subscriptions'], { relativeTo: this._route });
        break;
      case 'report-config':
        this._router.navigate(['/configuration/report-config'], { relativeTo: this._route });
        break;
      case 'home':
        this._router.navigate(['/home']);
        break;
      case 'avgPriceReport':
        this._router.navigate(['/view-file/avgPriceReport']);
        break;
      case 'lmpMccReport':
        this._router.navigate(['/view-file/lmpMccReport']);
        break;
      case 'fuelPricing':
        this._router.navigate(['/view-file/fuelPricing']);
        break;
      case 'signOut':
        this._authService.signOut();
        break;
    }
  }

  onLogoClick() {
    this._router.navigate(['/home']);
  }

  ngOnDestroy(): void {
    if (this.userSubscription$) {
      this.userSubscription$.unsubscribe();
    }
  }

}
