import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from '../../../../services/api/users.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html'
})
export class DeleteUserComponent {

  public cancelTxt: string = 'Cancel';
  public deleteTxt: string = 'Delete';

  constructor(
    public dialogRef: MatDialogRef<DeleteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _us: UsersService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDeleteClick() {
    this._us.selectedUser$.next(null);
    this._us.deleteUser(this.data);
  }

}
