import { env } from '../../config/env';
import { environments } from '../../config/env.config';
import { ILoggerCallback } from '@azure/msal-common';
import {
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environments[env.env].azure_client_id,
      authority: 'https://login.microsoftonline.com/' + environments[env.env].azure_tenant_id,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: environments[env.env].unauthorized
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const map = new Map<string, Array<string>>();
  map.set(environments[env.env].lambdaBaseUrl, [environments[env.env].azure_client_id + '/Read.All']);
  map.set(environments[env.env].graph_microsoft, ['User.Read', 'Group.Read.All', 'GroupMember.Read.All', 'User.Read.All']);
  map.set(environments[env.env].apiBaseUrl, [environments[env.env].azureApiScope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: map
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['User.Read'],
    },
    loginFailedRoute: environments[env.env].unauthorized
  };
}

export const loggerCallback: ILoggerCallback = (logLevel: LogLevel, message: string) => {
  console.info(`From MSAL: ${message}`);
};
