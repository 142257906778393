import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { UsersService } from '../../../../services/api/users.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {

  emailFormControl = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50), this.emailValidator ]);
  public email = this.data.mail;
  private _user: string;

  private _userSubscription$: Subscription;

  public requiredTxt: string = 'required';
  public cancelTxt: string = 'Cancel';
  public saveTxt: string = 'Save';

  constructor(
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _us: UsersService,
    private _auth: AuthenticationService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onEditClick(email: string): void {
    const req = {
      email_address: email,
      id: this.data.id,
      updated_by: this._user
    };
    this._us.editUser(req);
  }

  ngOnInit(): void {
    this._userSubscription$ = this._auth.getUser().subscribe(user => this._user = user.username);
  }

  emailValidator(control: AbstractControl):  ValidationErrors | null {
      const forbidden = control?.value?.toLowerCase().includes('@duke-energy.com');
      return forbidden ? {forbiddenEmail: {value: control.value}} : null;
  }

  ngOnDestroy(): void {
    this._userSubscription$.unsubscribe();
  }

}
