import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { ErrorComponent } from './components/shared/error/error.component';
import { HomeComponent } from './components/shared/home/home.component';
import { UnauthorizedComponent } from './components/shared/unauthorized/unauthorized.component';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';
import { ViewFileComponent } from './components/view-file/view-file.component';

const routes: Routes = [
    {
        path: '',
        canLoad: [MsalGuard],
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'plant-notifications',
        canLoad: [MsalGuard],
        loadChildren: () => import('./components/plant-subscription/plant-subscription.module').then(m => m.PlantSubscriptionModule)

    },
    {
        path: 'reports',
        canLoad: [MsalGuard],
        loadChildren: () => import('./components/report-subscription/report-subscription.module').then(m => m.ReportSubscriptionModule)

    },
    {
        path: 'configuration',
        canLoad: [MsalGuard],
        loadChildren: () => import('./components/configuration/configuration.module').then(m => m.ConfigurationModule)

    },
    {
        path: 'home',
        canLoad: [MsalGuard],
        component: HomeComponent
    },
    {
        path: 'sample-report/:report',
        canLoad: [MsalGuard],
        component: ImageLoaderComponent
    },
    {
        path: 'view-file/:reportFile',
        canLoad: [MsalGuard],
        component: ViewFileComponent
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path: 'error',
        component: ErrorComponent
    },
    {
        path: '**',
        redirectTo: 'home'
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // Don't perform initial navigation in iframes or popups
        initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabled' : 'disabled' // Remove this line to use Angular Universal
      })],
    exports: [RouterModule]
})
export class AppRoutingModule {}