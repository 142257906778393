import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BasicsService } from 'src/app/services/shared/basics.service';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html'
})
export class ImageLoaderComponent implements OnInit {

  imgSrc = '';

  constructor(private _route: ActivatedRoute, private _bs: BasicsService) { }

  ngOnInit(): void {
    this.loadImage();
  }

  loadImage(): void {
    this._bs.showHeader.next(false);
    const route = this._route.snapshot.url.join('/').split('/').pop();
    this.imgSrc = `../../../assets/img/${route}.png`;
  }

}
