const azure_client_id = '1ef18e99-ee79-4891-8242-b0280dcad4fe',
    azure_tenant_id = '2ede383a-7e1f-4357-a846-85886b2c0c4d',
    unauthorizedRoute = "/unauthorized",
    graph_microsoft = 'https://graph.microsoft.com';

export const environments = {
    local: {
      production: false,
      envName: 'local',
      apiBaseUrl: 'http://localhost:4200/',
      azure_client_id,
      azure_tenant_id,
      azureApiScope: `api://${azure_client_id}/api.read.write`,
      redirect_uri: 'http://localhost:4200/',
      unauthorizedRoute,
      lambdaBaseUrl: 'https://5mrh2y5ima.execute-api.us-east-1.amazonaws.com',
      graph_microsoft
    },
    sbx: {
      production: false,
      envName: 'sbx',
      apiBaseUrl: 'https://bpmreportingsbx.duke-energy.app/',
      azure_client_id,
      azure_tenant_id,
      baseUrl: 'https://bpmreportingsbx.duke-energy.app/',
      azureApiScope: `api://${azure_client_id}/api.read.write`,
      redirect_uri: 'https://bpmreporting-sbx.duke-energy.app/',
      unauthorizedRoute,
      lambdaBaseUrl: 'https://bpmreporting-api-sbx.duke-energy.app',
      graph_microsoft
    },
    dev: {
      production: false,
      envName: 'dev',
      apiBaseUrl: 'https://bpmreportingsbx.duke-energy.app/',
      azure_client_id,
      azure_tenant_id,
      baseUrl: 'https://bpmreportingdev.duke-energy.app/',
      azureApiScope: `api://${azure_client_id}/api.read.write`,
      redirect_uri: 'https://bpmreportingdev.duke-energy.app/',
      unauthorizedRoute,
      lambdaBaseUrl: 'https://bpmreporting-api-dev.duke-energy.app',
      graph_microsoft
    },
    tst: {
      production: false,
      envName: 'test',
      baseUrl: 'https://emp-tst-api.duke-energy.com/your-proxy-name/',
      clientKey: '---',
      clientSecret: '---',
    },
    qa: {
      production: false,
      envName: 'qa',
      apiBaseUrl: 'https://bpmreportingsbx.duke-energy.app/',
      azure_client_id,
      azure_tenant_id,
      baseUrl: 'https://bpmreportingqa.duke-energy.app/',
      azureApiScope: `api://${azure_client_id}/api.read.write`,
      redirect_uri: 'https://bpmreportingqa.duke-energy.app/',
      unauthorizedRoute,
      lambdaBaseUrl: 'https://bpmreporting-api-qa.duke-energy.app',
      graph_microsoft
    },
    prod: {
      production: true,
      envName: 'prod',
      apiBaseUrl: 'https://bpmreporting.duke-energy.app/',
      azure_client_id: '234883d0-be69-46ac-ac25-557cf1cef36e',
      azure_tenant_id,
      baseUrl: 'https://bpmreporting.duke-energy.app/',
      azureApiScope: `api://${azure_client_id}/api.read.write`,
      redirect_uri: 'https://bpmreporting.duke-energy.app/',
      lambdaBaseUrl: 'https://bpmreporting-api.duke-energy.app',
      graph_microsoft
    },
  };