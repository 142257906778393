<div class="vf-container">
  <mat-card class="bpm-border-radius bpm-min-width" *ngIf="!dataLoaded">
    No data available
  </mat-card>
  <mat-card class="bpm-border-radius bpm-min-width" *ngIf="dataLoaded">
    <mat-card-title>View/Upload File</mat-card-title>
    <mat-card-content class="bpm-card-content">
      <div class="vf-flex">
        <div class="bpm-fileview">
          <mat-form-field class="search-form-field" appearance="fill">
            <mat-label for="filter_input">search file</mat-label>
            <input
              id="filter_input"
              name="filter_email"
              aria-label="filter_email"
              matInput
              type="text"
              [(ngModel)]="searchText"
            />
            <button
              *ngIf="searchText"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="searchText = ''"
            >
              <mat-icon fontIcon="home">{{ close }}</mat-icon>
            </button>
          </mat-form-field>

          <mat-selection-list
            #selectedFileRef
            (selectionChange)="
              onFileClick(selectedFileRef?.selectedOptions.selected[0]?.value)
            "
            [multiple]="false"
          >
            <ng-container *ngIf="fileList?.report === 'fuelPricing'">
              <span matListItemTitle class="bpm-text-center reportTitle"
                >MIRA Offer Check Update File View</span
              >
              <div
                *ngFor="
                  let children of fileList?.children | sort : 'desc' : 'name';
                  let i = index
                "
              >
                <mat-list-option
                  *ngIf="
                    children?.name
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  "
                  [selected]="false"
                  class="file-list"
                  [value]="children"
                >
                  <div class="option">
                    <span>
                      {{ children?.name }}
                    </span>
                  </div>
                </mat-list-option>
              </div>
            </ng-container>

            <ng-container *ngIf="fileList?.report === 'lmpMcc'">
              <span matListItemTitle class="bpm-text-center reportTitle"
                >LMP & MCC Basis Report File View</span
              >
              <mat-accordion
                [multi]="true"
                *ngFor="
                  let children of fileList.children | sort : 'desc' : 'folder';
                  let i = index
                "
              >
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span class="bpm-text-center">{{
                        children?.folder
                      }}</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  
                  <mat-accordion
                    [multi]="true"
                    *ngFor="let month of children?.children; let j = index">
                    <mat-expansion-panel [expanded]="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <span class="bpm-text-center">{{month?.folder}}</span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <div *ngFor="let file of month?.children | sort : 'desc' : 'key'">
                        <mat-list-option
                          *ngIf="file?.name.toLowerCase().includes(searchText.toLowerCase())"
                          [selected]="false"
                          class="file-list"
                          [value]="file"
                        >
                          <div class="option">
                            <span>
                              {{ file?.name }}
                            </span>
                          </div>
                        </mat-list-option>
                      </div>

                    </mat-expansion-panel>
                  </mat-accordion>               


                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>

            <ng-container *ngIf="fileList?.report === 'avgPrice'">
              <span matListItemTitle class="bpm-text-center reportTitle"
                >Average Price Export Report File View</span
              >
              <mat-accordion
                [multi]="true"
                *ngFor="
                  let children of fileList.children | sort : 'desc' : 'folder';
                  let i = index
                "
              >
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span class="bpm-text-center">{{
                        children?.folder
                      }}</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div
                    *ngFor="
                      let file of children?.children | sort : 'desc' : 'name';
                      let j = index
                    "
                  >
                    <mat-list-option
                      *ngIf="
                        file?.name
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      "
                      [selected]="false"
                      class="file-list"
                      [value]="file"
                    >
                      <div class="option">
                        <span>
                          {{ file?.name }}
                        </span>
                      </div>
                    </mat-list-option>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>
          </mat-selection-list>
        </div>
        <div class="bpm-fileupload">
          <div
            [draggable]="isDraggable"
            [ngClass]="isDraggable ? 'dragfile' : 'disableDrag'"
            class="dragArea"
          >
            <div class="upload_icon">
              <mat-icon class="mat-icon-font">upload_file</mat-icon>
            </div>
            <div>
              <span>Drag and Drop files to upload or</span>
              <label for="fileInput" class="sr-only"></label>
              <input
                id="fileInput"
                type="file"
                #myFileInput
                [multiple]="false"
                accept=".xls, .xlsx, .xlsm, .csv"
                (change)="onFileChange($event)"
                (click)="$event.target.value = null"
                (keydown)="$event.target.value = null"
              />
            </div>
            <div>
              <button
                mat-raised-button
                [disabled]="!isDraggable"
                color="primary"
                (click)="myFileInput.click()"
              >
                {{ browseTxt }}
              </button>
            </div>
            <div *ngIf="fileName && fileSize" class="file">
              <span class="file-name">{{ fileName }} {{ fileSize }}</span>
              <button
                matSuffix
                mat-icon-button
                aria-label="Remove"
                (click)="removeFile()"
              >
                <mat-icon fontIcon="home">{{ close }}</mat-icon>
              </button>
            </div>
            <div class="error" *ngIf="error">
              {{ error }}
            </div>
          </div>
          <div *ngIf="fileList?.report === 'fuelPricing'" class="file-warning">
            <span>
              "Please follow the file naming convention "MiraExport_yyyymmdd.xlsx to upload the file!"
            </span>
            <span>
              (Please be noted the file naming convention is case sensitive.)
              <mat-icon class="mat-icon-font">feedback</mat-icon>
            </span>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="bpm-footer">
  <button
    mat-raised-button
    color="primary"
    class="bpm-border-radius"
    [disabled]="!fileName && !fileSize"
    (click)="uploadFile()"
  >
    Upload File
  </button>
  <button
    mat-stroked-button
    color="primary"
    class="bpm-border-radius bpm-margin"
    [disabled]="!fileName && !fileSize"
    (click)="removeFile()"
  >
    {{ cancelTxt }}
  </button>
</div>
