import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersListComponent } from '../components/shared/users-list/users-list.component';
import { AddUserComponent } from '../components/shared/users-list/add-user/add-user.component';
import { InstructionsComponent } from '../components/shared/home/instructions/instructions.component';
import { DeElectronModule } from './de-electron.module';
import { AngularMatModule } from './angular.mat.module';

const modules = [
    CommonModule,
    DeElectronModule,
    AngularMatModule,
    FormsModule,
    ReactiveFormsModule,
];

const components = [
    UsersListComponent,
    AddUserComponent,
    InstructionsComponent
];

@NgModule({
    declarations: [...components],
    imports: [...modules],
    exports: [...modules, components],
})
export class SharedModule {}