<div class="bpm-home" *ngIf="enableNav">
    <mat-card>
        <mat-card-content>
            <p>Plant-Notification-Subscription</p>
        </mat-card-content>
        <mat-card-footer class="bpm-card-footer">
            <button
                class="bpm-border-radius"
                mat-raised-button
                color="primary"
                (click)="navigateToPlant()">Plant-Notifications</button>
        </mat-card-footer>
    </mat-card>
    <mat-card>
        <mat-card-content>
            <p>Reporting-Subscription</p>
        </mat-card-content>
        <mat-card-footer class="bpm-card-footer">
            <button
                class="bpm-border-radius"
                mat-raised-button
                color="primary"
                (click)="navigateToReport()">Report-Subscriptions</button>
        </mat-card-footer>
    </mat-card>
    <mat-card>
        <mat-card-content>
            <p>How to ... ?</p>
        </mat-card-content>
        <mat-card-footer class="bpm-card-footer">
            <button
                class="bpm-border-radius"
                mat-raised-button
                color="primary"
                (click)="openInstructions()">See instructions</button>
        </mat-card-footer>
    </mat-card>
</div>