import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environments } from '../../../config/env.config';
import { env } from '../../../config/env';
import { BehaviorSubject, EMPTY, forkJoin, Subject, Subscription } from 'rxjs';
import { expand, map, reduce, retry, takeUntil, timeout } from 'rxjs/operators';
import { IUserListItem, IExUser } from '../../models/user.interface';
import { BasicsService } from '../shared/basics.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    public userRole = new BehaviorSubject<string>('unauthorized');
    public isProductTeam = new BehaviorSubject<boolean>(false);

    public selectedUser$ = new BehaviorSubject<IUserListItem>(null);

    private _azureUsers$ = new BehaviorSubject<IUserListItem[]>(null);
    public azureUsers = this._azureUsers$.asObservable();

    private _externalUser$ = new BehaviorSubject<IUserListItem[]>(null);
    public externalUser = this._externalUser$.asObservable();

    public destroyAzure = new Subject<boolean>();
    public destroyExUser = new Subject<boolean>();
    public destroyAddUser = new Subject<boolean>();
    public destroyDeleteUser = new Subject<boolean>();
    public destroyEditUser = new Subject<boolean>();

    constructor(
        private _http: HttpClient,
        private _bs: BasicsService,
        private _router: Router
    ) { }

    onInit() {
        this.getAzureGroups(['d6b10898-d601-4a44-baab-942943c7bd9b', 'b37db569-e408-4272-92d3-eac2f4a2baae', 'a65d4a7f-d6e2-40df-ac2a-3e149527e315']);
    }

    getAzureGroups(groupIds: string[]): Subscription {
        this.destroyAzure.next(false);
        const groupObservables = groupIds.map(groupId => {
            const url = `${environments[env.env].graph_microsoft}/v1.0/groups/${groupId}/members/microsoft.graph.user?&$select=id,mail`;
            return this._http.get<IUserListItem[]>(url).pipe(
                expand(res => {
                    if (res['@odata.nextLink']) {
                        const nextUrl = res['@odata.nextLink'];
                        return this._http.get<IUserListItem[]>(nextUrl);
                    } else {
                        return EMPTY;
                    }
                }),
                reduce((acc, res) => {
                    return acc.concat(res['value']);
                }, [])
            );
        });
        return forkJoin(groupObservables).pipe(
            takeUntil(this.destroyAzure),
            map(users => {
                return [].concat(...users);
            })
        )
            .subscribe(users => {
                const newUserArr = users.map(x => {
                    return {
                        id: x.id,
                        mail: x.mail,
                        is_internal: true
                    };
                });
                this._azureUsers$.next(newUserArr);
            });
    }

    getExternalUsers(): Subscription {
        this.destroyExUser.next(false);
        return this._http.get<{ external_user: IExUser[] }>(environments[env.env].lambdaBaseUrl + `/external-users`)
            .pipe(
                takeUntil(this.destroyExUser),
                timeout(25000),
                retry(2),
                map(x => {
                    return x.external_user.map(user => {
                        const userValue: IUserListItem = {
                            id: user.id,
                            mail: user.email_address,
                            is_internal: false
                        };
                        return userValue;
                    });
                }))
            .subscribe({
                next: user => {
                    this._externalUser$.next(user);
                },
                error: e => this._bs.openSnackBar(e?.error?.error ?? 'An error occured while retriving external user data', false)
            });
    }

    addUser(req) {
        this.destroyAddUser.next(false);
        return this._http.post(`${environments[env.env].lambdaBaseUrl}/external-users/`, req)
            .pipe(takeUntil(this.destroyAddUser))
            .subscribe({
                next: (x: any) => {
                    const extUserId = {
                        id: x.id,
                        mail: req.email_address,
                        is_internal: false
                    };
                    this.selectedUser$.next(extUserId);
                    this._bs.openSnackBar(x.success ?? 'External user added successfully');
                    this.getExternalUsers();
                },
                error: (e) => this._bs.openSnackBar(e?.error?.error ?? 'An error occured while adding external user data', false)
            });
    }

    deleteUser(user) {
        return this._http.delete(`${environments[env.env].lambdaBaseUrl}/external-users?user_id=${user.id}`)
            .pipe(takeUntil(this.destroyDeleteUser))
            .subscribe({
                next: (x: any) => {
                    this._bs.openSnackBar(x.success ?? 'External user deleted successfully');
                    this.getExternalUsers();
                    this.selectedUser$.next(null);
                },
                error: e => {
                    this._bs.openSnackBar(e?.error?.error ?? 'An error occured while deleting external user data', false);
                }
            });
    }

    editUser(body) {
        return this._http.put(`${environments[env.env].lambdaBaseUrl}/external-users?user_id=${body.id}`, body)
            .pipe(takeUntil(this.destroyEditUser))
            .subscribe({
                next: (x: any) => {
                    this._bs.openSnackBar(x.success ?? 'Data saved successfully');
                    const selectedUser = {
                        id: body.id,
                        mail: body.email_address,
                        is_internal: false
                      };
                    this.selectedUser$.next(selectedUser);
                    this.getExternalUsers();
                },
                error: e => this._bs.openSnackBar(e?.error?.error ?? 'An error occured while editing external user data', false)
            });
    }
}
